import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { filter, fromEvent, map, mergeWith, Observable, Subscription, switchMap, take, tap, timer } from 'rxjs';
import { MatTabNavPanel } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RxState } from '@rx-angular/state';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Breakpoints } from '@angular/cdk/layout';

import {
    CloudToolbarUser,
    IconRegistryService,
    LAUNCH_SCREEN,
    LaunchScreen,
    Membership,
} from '@heidelberg/hdmui-angular';
import { CCAuthService, CCSession } from '@heidelberg/control-center-frontend-integration/auth';

import {
    CloudSessionService,
    DataTerminalSessionService,
    GLOBAL_RX_STATE,
    GlobalState,
    GlobalStateSelectors,
    HEI_CC_URL,
    isNavigationHidden,
    NavigationService,
} from '@data-terminal/data-access';
import {
    DeviceTypeService,
    getParams,
    IS_ROUTER_IN_LOADING_STATE,
    LoadingStateService,
    MS_IN_SECOND,
    PAGE_VISIBILITY_CHANGE,
    RequestMetadata,
    takeUntilPageVisible,
} from '@data-terminal/utils';
import { ROUTE_PATH, UserMachines } from '@data-terminal/shared-models';
import { TransitionService } from '@data-terminal/feature-transition';
import { GeneralActivitiesService } from '@data-terminal/feature-workstation-details';
import { SettingsConfirmationService, SettingsDataTransferService } from '@data-terminal/feature-settings';
import { Ga4Service } from '@data-terminal/app';

import { DashboardPresenter } from '../../../../projects/feature-dashboards/src/lib/pages/dashboard/dashboard.presenter';
import { UserMachinesService } from '../../../../projects/data-access/src/lib/user-machines';
import { OperationCardAttributeOrderService } from '../../../../projects/settings/src/lib/services/operation-card-attribute-order.service';
import { EmployeeService } from '../../../../projects/feature-workstation-details/src/lib/services/employee/employee.service';

const FIVE_SECONDS = 5;
const TEN_SECONDS = 10;

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
    private readonly USERS_MACHINE_REQUEST_INTERVAL = FIVE_SECONDS * MS_IN_SECOND;
    private readonly USERS_ACTIVITIES_REQUEST_INTERVAL = TEN_SECONDS * MS_IN_SECOND;

    @ViewChild('tabPanel') tabPanel!: MatTabNavPanel;

    public cloudUser!: CloudToolbarUser;
    public initialLoadDone = false;

    public showNavigation$: Observable<boolean>;
    public isLoading$: Observable<boolean>;
    public isProfileLoading$: Observable<boolean>;
    public hideBasicUIElements$: Observable<boolean>;

    public readonly userMachines$ = this.dashboardPresenter.userMachines$;

    private captainFlagChanged = false;

    public breakpoints = [
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletPortrait,
        Breakpoints.WebPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletLandscape,
        Breakpoints.WebLandscape,
    ];

    private readonly subscriptions = new Subscription();

    constructor(
        @Inject(LAUNCH_SCREEN) private readonly launchScreen: LaunchScreen,
        @Inject(IS_ROUTER_IN_LOADING_STATE) public isRouterInLoadingState$: Observable<boolean>,
        @Inject(GLOBAL_RX_STATE) private readonly globalState: RxState<GlobalState>,
        @Inject(PAGE_VISIBILITY_CHANGE) public readonly visibilityChange$: Observable<boolean>,
        @Inject(HEI_CC_URL) private readonly heiCCUrl: string,
        private readonly loadingStateService: LoadingStateService,
        private readonly ccAuthService: CCAuthService,
        private readonly navigationService: NavigationService,
        private readonly sessionService: DataTerminalSessionService,
        private readonly cloudSessionService: CloudSessionService,
        private readonly transitionService: TransitionService,
        private readonly dashboardPresenter: DashboardPresenter,
        private readonly userMachinesService: UserMachinesService,
        private readonly settingsConfirmationService: SettingsConfirmationService,
        private readonly settingsDataTransferService: SettingsDataTransferService,
        private readonly operationCardAttributeOrderService: OperationCardAttributeOrderService,
        private readonly employeeService: EmployeeService,
        private readonly router: Router,
        private readonly generalActivitiesService: GeneralActivitiesService,
        public readonly activatedRoute: ActivatedRoute,
        private readonly snackBar: MatSnackBar,
        public readonly deviceTypeService: DeviceTypeService,
        private readonly iconRegistryService: IconRegistryService,
        private readonly ga4Service: Ga4Service
    ) {
        this.subscriptions.add(
            fromEvent(window, 'ucEvent')
                .pipe(filter((e) => e instanceof CustomEvent && e.detail && e.detail.event === 'consent_status'))
                .subscribe(() => {
                    this.ga4Service.setConsentTrue();
                    this.ga4Service.pageView();
                })
        );

        this.isProfileLoading$ = this.loadingStateService.isProfileLoading$;
        this.isLoading$ = this.loadingStateService.isLoading$;
        this.showNavigation$ = this.navigationService.navData$.pipe(isNavigationHidden);
        this.hideBasicUIElements$ = this.shouldHideUIElements$();
        this.iconRegistryService.registerWatermark('./assets/icons/HDM-Data-Capture-Cutout.svg');

        this.subscriptions.add(
            router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe(() => this.ga4Service.pageView())
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public ngAfterViewInit(): void {
        const sessionLoadReady = this.ccAuthService.isInitialLoadFinished();

        if (sessionLoadReady) {
            this.launchScreen.hide();
        }

        setTimeout(() => {
            this.initialLoadDone = this.ccAuthService.isInitialLoadFinished();
        }, 100);

        const userMail = this.ccAuthService.getCurrentUser()?.email;
        const orgName = this.ccAuthService.getCurrentMembership()?.organization.name;

        if (userMail && orgName) {
            this.ccAuthService.currentSession$
                .pipe(
                    tap((session) => {
                        this.cloudUser = this.getToolbarUserFromSession(session);
                    }),
                    switchMap(() => this.sessionService.logInToDataTerminal(userMail, orgName)),
                    take(1),
                    switchMap(() => this.cloudSessionService.getLatestUserSession().pipe(take(1))),
                    tap(() => this.sessionService.keepAlive()),
                    switchMap(() => this.settingsConfirmationService.fetchConfirmationSettings()),
                    switchMap(() => this.settingsDataTransferService.fetchDataTransferSettings()),
                    switchMap(() => this.employeeService.fetchUserMachineLogins(userMail)),
                    switchMap(() => this.operationCardAttributeOrderService.fetchOperationCardAttributes()),
                    switchMap(() => this.transitionService.shouldDisplayWelcomeScreen$),
                    tap((displayWelcomeScreen) =>
                        displayWelcomeScreen
                            ? this.transitionService.navigateToWelcomePage()
                            : this.navigationService.redirectToInitialRoute()
                    )
                )
                .subscribe({
                    complete: () => {
                        this.navigationService.initRoutingTracking();
                        this.loadingStateService.setProfileLoading(false);
                        this.listenForUserMachines();
                        this.listenForOtherActivities();
                    },
                });
        }
    }

    public onLogout(): void {
        this.ccAuthService.signOut();
    }

    private getToolbarUserFromSession(s: CCSession): CloudToolbarUser {
        const orgId = s.activeMembership?.organizationId;
        return {
            id: s.user.id,
            fullName: s.user.fullName,
            avatarUrl: s.user.avatarUrl ?? undefined,
            activeOrganizationId: orgId ?? '',
            memberships:
                Object.keys(s.user.memberships).length > 0
                    ? s.user.memberships.map((m) => {
                          const orgMembership: Membership = {
                              id: m.id,
                              organization: {
                                  id: m.organizationId,
                                  name: `${m.organization.name}`,
                                  logoUrl: m.organization.logoUrl ?? undefined,
                              },
                              roles: m.ccRoles,
                          };
                          return orgMembership;
                      })
                    : [],
        };
    }

    private shouldHideUIElements$(): Observable<boolean> {
        return this.router.events.pipe(
            untilDestroyed(this),
            filter((event) => event instanceof NavigationEnd),
            map((event) => event as NavigationEnd),
            map(({ url }) => {
                return (
                    url.endsWith(`/${ROUTE_PATH.welcome}`) ||
                    url.endsWith(`/${ROUTE_PATH.farewell}`) ||
                    url.includes(`/${ROUTE_PATH.inactivityTimer}/`)
                );
            })
        );
    }

    private listenForUserMachines(): void {
        const prevUserMachines: RequestMetadata<UserMachines> | null = null;

        this.globalState.connect(
            GlobalStateSelectors.USER_MACHINES,
            this.userMachinesService
                .getUserMachines()
                .pipe(
                    mergeWith(
                        this.userMachinesService
                            .getUpdateMachinesTrigger$()
                            .pipe(switchMap(() => this.userMachinesService.getUserMachines())),
                        this.userMachinesService.machinesAfterSignOff$,
                        this.dashboardPresenter.assignMachinesTrigger$
                    )
                )
        );
        this.startRefreshingUserMachines(prevUserMachines);
    }

    private startRefreshingUserMachines(prevUserMachines: RequestMetadata<UserMachines> | null): void {
        this.visibilityChange$
            .pipe(
                untilDestroyed(this),
                filter((isVisible) => isVisible),
                switchMap(() =>
                    timer(0, this.USERS_MACHINE_REQUEST_INTERVAL).pipe(takeUntilPageVisible(this.visibilityChange$))
                )
            )
            .subscribe(() => {
                this.userMachinesService.triggerUpdateMachines();
            });

        this.globalState
            .select(GlobalStateSelectors.USER_MACHINES)
            .pipe(untilDestroyed(this))
            .subscribe((userMachines) => {
                if (prevUserMachines && !this.captainFlagChanged) {
                    const monitoredUserMachine = userMachines.data?.assignedMachines.find((machine) =>
                        machine.signedOnUsers.some(
                            (user) => user.userId === this.sessionService.getCurrentDataTerminalUser().userId
                        )
                    );
                    const prevMonitoredUserMachine = prevUserMachines.data?.assignedMachines.find((machine) =>
                        machine.signedOnUsers.some(
                            (user) => user.userId === this.sessionService.getCurrentDataTerminalUser().userId
                        )
                    );

                    if (
                        monitoredUserMachine &&
                        prevMonitoredUserMachine &&
                        prevMonitoredUserMachine.signedOnUsers.some(
                            (user) =>
                                user.userId === this.sessionService.getCurrentDataTerminalUser().userId && user.captain
                        ) && // Previous state: Current user was a Captain
                        monitoredUserMachine.signedOnUsers.some(
                            (user) =>
                                user.userId === this.sessionService.getCurrentDataTerminalUser().userId && !user.captain
                        ) // Current state: Current user is not a Captain
                    ) {
                        this.snackBar.open(
                            `You have lost your special user permissions on machine ${monitoredUserMachine.machineId}`,
                            'Close',
                            {
                                duration: 5000,
                            }
                        );
                        this.captainFlagChanged = true;
                    }
                }
                //redirect to dashboard in case of logout by OnPrem-Backend (or caused by other external sources)
                const { machineId, orgId } = getParams(this.activatedRoute);
                if (machineId) {
                    if (!userMachines.data?.assignedMachines.find((machine) => machine.machineId === machineId)) {
                        const url = `${orgId}/dashboard`;
                        this.router.navigateByUrl(url);
                    }
                }
                prevUserMachines = userMachines;
            });
    }

    private listenForOtherActivities(): void {
        this.globalState.connect(
            GlobalStateSelectors.OTHER_ACTIVITIES,
            this.generalActivitiesService
                .getUpdateOtherActivitiesTrigger$()
                .pipe(switchMap(() => this.generalActivitiesService.getAllActivities()))
        );

        this.startRefreshingOtherActivites();
    }

    private startRefreshingOtherActivites(): void {
        this.visibilityChange$
            .pipe(
                untilDestroyed(this),
                filter((isVisible) => isVisible),
                switchMap(() =>
                    timer(0, this.USERS_ACTIVITIES_REQUEST_INTERVAL).pipe(takeUntilPageVisible(this.visibilityChange$))
                )
            )
            .subscribe(() => {
                this.generalActivitiesService.triggerUpdateOtherActivities();
            });
    }

    public onScreenBlockerRedirect(): void {
        window.location.href = this.heiCCUrl;
    }
}
